
/**********************************************************/


.swipe-right-switch {
    -webkit-animation: swipe-right-switch 0.3s  cubic-bezier(0.54, 0.01, 0.54, 0.99) both;;
    animation: swipe-right-switch 0.3s  cubic-bezier(0.54, 0.01, 0.54, 0.99) both;;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-10-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swipe-right-switch
 * ----------------------------------------
 */
@-webkit-keyframes swipe-right-switch {
    0% {
        right: 47%;
    }
    100% {
        right: -10%;

    }
}
@keyframes swipe-right-switch {
    0% {
        right: 47%;
    }
    100% {
        right: -10%;
    }
}


/**********************************************************/


.swipe-left-switch {
    -webkit-animation: swipe-left-switch 0.3s  cubic-bezier(0.54, 0.01, 0.54, 0.99) both;;
    animation: swipe-left-switch 0.3s  cubic-bezier(0.54, 0.01, 0.54, 0.99) both;;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-10-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swipe-left-switch
 * ----------------------------------------
 */
@-webkit-keyframes swipe-left-switch {
    0% {
        right: -10%;
    }
    100% {
        right: 47%;

    }
}
@keyframes swipe-left-switch {
    0% {
        right: -10%;
    }
    100% {
        right: 47%;
    }
}
