.divFile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2vw;
    color: white;
}

.App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    max-width: 80vw;
}

.imgCropMee {
    max-width: 20vw;
    margin-left: 4vw;
}

.ReactCrop__image {
    display: block;
    max-width: 35vw;
    touch-action: manipulation;
}

@media only screen and (max-width: 600px) {
    .App {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .ReactCrop__image {
        display: block;
        max-width: 65vw;
        touch-action: manipulation;
    }

    .imgCropMee {
        max-width: 30vw;
        margin-top: 4vw;
    }
}