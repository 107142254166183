
.slide-left {
    -webkit-animation: slide-left 3s  cubic-bezier(0, 0.71, 0.58, 1) both;
    animation: slide-left 3s cubic-bezier(0, 0.71, 0.58, 1) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
    0% {
        left: -200%;
        display: block;
    }
    30% {
        left: 15%;
        display: block;
    }
    60% {
        left: 15%;
        display: block;
    }
    100% {
        left: 200%;
        display: block;
    }
}
@keyframes slide-left {
    0% {
        left: -200%;
        display: block;
    }
    30% {
        left:15%;
        display: block;
    }
    60% {
        left: 15%;
        display: block;
    }
    100% {
        left: 200%;
        display: block;
    }
}

/*******************************************************************/


/**********************************************************/


.action-button-animation {
    -webkit-animation: action-button-animation 0.3s  cubic-bezier(0.600, -0.280, 0.735, 0.045) both;;
    animation: action-button-animation 0.3s  cubic-bezier(0.600, -0.280, 0.735, 0.045) both;;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation action-button-animation
 * ----------------------------------------
 */
@-webkit-keyframes action-button-animation {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes action-button-animation {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}


