input:focus {
    outline: none;
}



.go-away-left-login{
    -webkit-animation: go-away-left-login 1s  cubic-bezier(0,-0.12, 0, 0.98) both;
    animation: go-away-left-login 1s cubic-bezier(0,-0.12, 0, 0.98) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation go-away-down-trip
 * ----------------------------------------
 */
@-webkit-keyframes go-away-left-login {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}
@keyframes go-away-left-login {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

.go-from-right-login{
    -webkit-animation: go-from-right-login 1s  cubic-bezier(0,-0.12, 0, 0.98) both;
    animation: go-from-right-login 1s cubic-bezier(0,-0.12, 0, 0.98) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation go-away-down-trip
 * ----------------------------------------
 */
@-webkit-keyframes go-from-right-login {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}
@keyframes go-from-right-login {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}






.go-back-left-login{
    -webkit-animation: go-back-left-login 1s  cubic-bezier(0,-0.12, 0, 0.98) both;
    animation: go-back-left-login 1s cubic-bezier(0,-0.12, 0, 0.98) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation go-away-down-trip
 * ----------------------------------------
 */
@-webkit-keyframes go-back-left-login {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}
@keyframes go-back-left-login {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

.go-back-right-login{
    -webkit-animation: go-back-right-login 1s  cubic-bezier(0,-0.12, 0, 0.98) both;
    animation: go-back-right-login 1s cubic-bezier(0,-0.12, 0, 0.98) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation go-away-down-trip
 * ----------------------------------------
 */
@-webkit-keyframes go-back-right-login {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}
@keyframes go-back-right-login {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}



.fade-back-btn{
    -webkit-animation: fade-back-btn 1s  cubic-bezier(0,-0.12, 0, 0.98) both;
    animation: fade-back-btn 1s cubic-bezier(0,-0.12, 0, 0.98) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 16:45:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation go-away-down-trip
 * ----------------------------------------
 */
@-webkit-keyframes fade-back-btn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes go-back-right-login {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}









